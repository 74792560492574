import { __, compose, contains, prop } from 'ramda';
import { Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IUISupplier, IWeddingSupplier, Slug } from '@bridebook/toolbox/src/types';
import { getSupplierL10nAnalytics } from 'lib/analytics-utils/get-supplier-l10n';
import { getPreviousPath } from 'lib/app/selectors';
import {
  AddedSupplierVisitDateAnalyticsAction,
  EditedSupplierVisitDateAnalyticsAction,
  RemovedSupplierVisitDateAnalyticsAction,
  ToggledTabNavigationOnShortlistAnalyticsAction,
} from 'lib/shortlist/action-types';
import { bookedSupplierAnalytics } from 'lib/shortlist/actions';
import { Action, IApplicationState } from 'lib/types';
import { getCurrentUserId } from 'lib/users/selectors';
import { IServerSideTrack } from 'lib/utils/server-side-track';
import bookedSupplierTrack from 'lib/utils/server-side-track/booked-supplier-track';
import { createWeddingLevelIdentify, getLocationName } from '../analytics-utils';
import { CriticalWebEvents } from '../analyticsTypes';
import { WebAnalyticsContext } from '../bbcommon/utils/bridebook-analytics';
import { getPremium } from '../supplier/selectors';
import { ShortlistActionTypes } from './action-types';
import { getFromBookedSuppliers } from './selectors';
import { ShortlistSupplierPropertiesGeneric } from './types';

export const filterLabelProps = (props: Record<string, any>) => {
  const label = { ...props };
  delete label.contactEmail;
  delete label.contactMessage;
  delete label.contactName;
  delete label.contactedSupplierEmail;
  delete label.contactedSupplierProfileURL;
  delete label.contactedSupplierThumbnailURL;
  delete label.contactedSupplierAccountEmail;
  delete label.contactedSupplierInvite;
  delete label.contactPartnerName;
  delete label.contactAdditionalDetails;
  delete label.bookedLocation;
  delete label.value;
  return label;
};

export const getCustomSupplierFormLocation = (getState: () => IApplicationState): string => {
  const {
    app: { pathname, query },
    shortlist: { customSupplierFormMethod },
    venueConfirmation: { showVenueConfirm },
  }: IApplicationState = getState();
  const is = (path: string) => pathname.includes(path);

  switch (true) {
    case showVenueConfirm:
      return 'bookingConfirmationPopup';
    case is('shortlist'):
      return query.category || 'venues';
    case is('onboarding'):
      return 'onboarding';
    case is('home'):
      return 'homeTab';
    default:
      return customSupplierFormMethod || 'shortlist';
  }
};

type IShortlistPropertiesGenericSupplier = {
  id: string;
  customSupplierType?: string;
  name?: string;
  booked?: boolean;
  type: Slug[] | Slug;
};
export const shortlistPropertiesGeneric = (
  supplier: IShortlistPropertiesGenericSupplier,
  state: IApplicationState,
): ShortlistSupplierPropertiesGeneric => {
  const { id, customSupplierType, name, booked } = supplier;
  const slug = Array.isArray(supplier.type) ? (supplier as ISupplier).type?.[0] : supplier.type;

  const customSupplier =
    typeof (supplier as IWeddingSupplier).customSupplier === 'boolean'
      ? (supplier as IWeddingSupplier).customSupplier
      : false;

  const premium = getPremium(state);

  return {
    shortlistedSupplierCategory: slug,
    shortlistSupplierId: id,
    shortlistSupplierName: name!,
    shortlistedSupplierStatus: booked ? 'booked' : 'shortlisted',
    shortlistedSupplierType: customSupplier || customSupplierType ? 'customSupplier' : 'listed',
    supplierTier: (supplier as IUISupplier)?.tier || premium?.tier || 0,
  };
};

export const getCustomSupplierFlag = (website: string = '', email: string = '') => {
  const domain = email.replace(/.*@/, '');
  return website !== '' && website.includes(domain);
};

export const customSupplierPropertiesGeneric = ({
  id,
  slug,
  name,
  website,
  phone,
  email,
  address,
  town,
  county,
  country,
  postcode,
  method,
  professional,
}: any) => ({
  customSupplierAddress: address,
  customSupplierCategory: slug,
  customSupplierCountry: country,
  customSupplierCounty: county,
  customSupplierEmail: email,
  customSupplierFlag: getCustomSupplierFlag(website, email),
  customSupplierId: id,
  // FIXME: isn't duplicated by customSupplierFormLocation??
  customSupplierMethod: method,
  customSupplierName: name,
  customSupplierPhone: phone,
  customSupplierPostcode: postcode,
  customSupplierTown: town,
  customSupplierWebsite: website,
  customSupplierType: professional ? 'business' : 'private',
});

export const shortlistAnalyticsEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(
      compose(
        contains(__, [
          'SUGGEST_SUPPLIER_FORM_TRIGGER',
          ShortlistActionTypes.TOGGLE_CUSTOM_SUPPLIER_FORM_MODAL,
          ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER_SUCCESS,
          ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER_ERROR,
        ]),
        prop('type'),
      ),
    ),
    mergeMap(({ type, payload: oldPayload }: Action) => {
      switch (type) {
        case 'SUGGEST_SUPPLIER_FORM_TRIGGER':
        case ShortlistActionTypes.TOGGLE_CUSTOM_SUPPLIER_FORM_MODAL: {
          const { show, finished } = oldPayload;
          const exitedCustomSupplier = () =>
            !finished ? of({ type: 'EXITED_CUSTOM_SUPPLIER_FORM_ANALYTICS' }) : of();

          return show
            ? of({
                type: 'TRIGGERED_CUSTOM_SUPPLIER_FORM_ANALYTICS',
              })
            : exitedCustomSupplier();
        }
        case ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER_SUCCESS: {
          const { supplier } = oldPayload;
          return of({
            type: 'COMPLETED_CUSTOM_SUPPLIER_FORM_ANALYTICS',
            payload: { supplier },
          });
        }
        case ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER_ERROR: {
          return of({
            type: 'FAILED_TO_COMPLETE_CUSTOM_SUPPLIER_FORM_ANALYTICS',
            payload: oldPayload,
          });
        }
        default:
          return of();
      }
    }),
  );

const category = 'Shortlist';

export default function shortlistAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
): void {
  switch (action.type) {
    case 'PB_STATUS_ANALYTICS': {
      const serverIdentify = createWeddingLevelIdentify<{ pbStatus: boolean }>(getState);
      const { pbStatus } = action.payload;
      serverIdentify({ pbStatus });
      break;
    }
    case ShortlistActionTypes.BOOKED_SUPPLIER_ANALYTICS:
    case ShortlistActionTypes.UNBOOK_SUPPLIER_ANALYTICS: {
      const state = getState();
      const { item: supplier, method } = action.payload as ReturnType<
        typeof bookedSupplierAnalytics
      >['payload'];
      const bookedSupplersMethod = getFromBookedSuppliers(state) ? 'bookedSuppliersCarousel' : '';
      const bookedLocation = bookedSupplersMethod || method || getLocationName(state);
      const userEmail = state.users.user?.contacts?.email;

      const price = supplier.price || '';
      const value = Number(supplier.price) || 0;
      const booked = action.type === ShortlistActionTypes.BOOKED_SUPPLIER_ANALYTICS;
      const { category: tabOpened } = getState().app.query;
      const supplierL10n = getSupplierL10nAnalytics(supplier);

      const analyticsProps: IServerSideTrack = {
        state,
        event: booked ? CriticalWebEvents.BOOKED_SUPPLIER : 'Unbooked supplier',
        specificEventProps: {
          bookedLocation,
          bookedValue: price,
          value,
          shortlistTab: booked ? undefined : tabOpened,
          ...shortlistPropertiesGeneric({ ...supplier, booked }, state),
          // Custom suppliers don't have a public id
          ...(booked && supplier.publicId && { shortlistPublicID: supplier.publicId }),
          ...(booked ? supplierL10n : {}),
        },
        bookedSupplier: supplier,
      };

      if (userEmail && booked) {
        analyticsProps.identifyProps = { userEmail };
      }

      bookedSupplierTrack(analyticsProps);

      break;
    }
    case 'EDITED_SUPPLIER_QUOTE_ANALYTICS':
    case 'ADDED_SUPPLIER_QUOTE_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { supplier } = action.payload;
      const edited = action.type === 'EDITED_SUPPLIER_QUOTE_ANALYTICS';
      const { category: tabOpened } = getState().app.query;

      track({
        event: edited ? 'Edited supplier quote' : 'Added supplier quote',
        ...shortlistPropertiesGeneric(supplier, getState()),
        bookedValue: supplier.price,
        shortlistedSupplierQuote: Number(supplier.price),
        shortlistTab: tabOpened,
      });
      break;
    }
    case 'SHORTLISTED_SUPPLIER_ANALYTICS':
    case 'REMOVED_SUPPLIER_FROM_SHORTLIST_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { method, supplier, shortlistedLocation, linkedSupplierName, linkedSupplierId } =
        action.payload;
      const shortlisted = action.type === 'SHORTLISTED_SUPPLIER_ANALYTICS';
      const supplierL10n = getSupplierL10nAnalytics(supplier);
      const { contactSection } = getState().enquiries.infoProps;

      track({
        event: shortlisted ? 'Shortlisted supplier' : 'Removed supplier from shortlist',
        ...shortlistPropertiesGeneric(supplier, getState()),
        shortlistedMethod: method,
        shortlistedLocation: shortlistedLocation || getLocationName(getState()),
        ...(linkedSupplierName && { linkedSupplierName }),
        ...(linkedSupplierId && { linkedSupplierId }),
        ...(shortlisted ? supplierL10n : {}),
        ...(contactSection && { shortlistedSection: contactSection }),
      });
      break;
    }
    case 'TRIGGERED_CUSTOM_SUPPLIER_FORM_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      track({
        event: 'Triggered custom supplier form',
        customSupplierFormLocation: getCustomSupplierFormLocation(getState),
      });
      break;
    }
    case 'EXITED_CUSTOM_SUPPLIER_FORM_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      track({
        event: 'Exited custom supplier form',
        customSupplierFormLocation: getCustomSupplierFormLocation(getState),
      });
      break;
    }
    case 'COMPLETED_CUSTOM_SUPPLIER_FORM_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { supplier } = action.payload;
      const customSupplierFormLocation = getCustomSupplierFormLocation(getState);

      track({
        event: 'Completed custom supplier form',
        ...customSupplierPropertiesGeneric({
          ...supplier,
          method: customSupplierFormLocation,
        }),
      });
      break;
    }
    case 'FAILED_TO_COMPLETE_CUSTOM_SUPPLIER_FORM_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { fields } = getState().shortlist.customSupplier;
      const customSupplierFormLocation = getCustomSupplierFormLocation(getState);

      track({
        event: 'Failed to complete custom supplier form',
        ...customSupplierPropertiesGeneric({
          ...fields,
          method: customSupplierFormLocation,
        }),
        reasonFailedCustomSupplier: action.payload ? action.payload.message : '',
      });
      break;
    }
    case 'USED_SHORTLIST_SUGGESTED_SUPPLIER_CAROUSEL_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { slug } = action.payload;
      track({
        event: 'Used shortlist suggested supplier carousel',
        shortlistedSupplierCategory: slug,
      });
      break;
    }
    case 'VIEWED_SUPPLIER_NOTES_POP_UP_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { supplier, notes: shortlistedSupplierNote } = action.payload;
      const shortlistedSupplierQuote = supplier.price;
      const { category: tabOpened } = getState().app.query;

      track({
        event: 'Viewed supplier notes pop up',
        shortlistedSupplierQuote,
        shortlistedSupplierNote,
        ...shortlistPropertiesGeneric(supplier, getState()),
        shortlistTab: tabOpened,
      });
      break;
    }
    case 'INTERACTED_WITH_CONTACT_DETAILS_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { supplier, shortlistContactDetailType } = action.payload;
      const { category: tabOpened } = getState().app.query;

      track({
        event: 'Interacted with contact details',
        ...shortlistPropertiesGeneric(supplier, getState()),
        shortlistContactDetailType,
        shortlistTab: tabOpened,
      });
      break;
    }
    case ShortlistActionTypes.TRIGGERED_ADD_SUPPLIER_POP_UP_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const pathname = getState().app.pathname;
      track({
        event: 'Triggered add supplier pop up',
        addSupplierPopupLocation: pathname.includes('shortlist') ? 'shortlist' : 'exterior',
      });
      break;
    }
    case 'ADDED_SUPPLIER_NOTE_ANALYTICS':
    case 'EDITED_SUPPLIER_NOTE_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { supplier } = action.payload;
      const addedNote = action.type === 'ADDED_SUPPLIER_NOTE_ANALYTICS';
      const { category: tabOpened } = getState().app.query;

      track({
        event: addedNote ? 'Added supplier note' : 'Edited supplier note',
        ...shortlistPropertiesGeneric(supplier, getState()),
        shortlistedSupplierNote: supplier.notes,
        shortlistTab: tabOpened,
      });
      break;
    }
    case ShortlistActionTypes.TRIGGERED_BOOKED_SUPPLIER_POP_UP_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { pathname } = getState().app;
      const bookedPopupLocation =
        (action.payload && action.payload.bookedPopupLocation) ||
        (pathname.includes('home') ? 'home' : 'booked');

      track({
        event: 'Triggered booked supplier pop up',
        bookedPopupLocation,
      });
      break;
    }
    case 'USED_GOOGLE_AUTOCOMPLETE_ANALYTICS': {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      track({
        event: 'Used Google autocomplete',
        customSupplierFormLocation: getCustomSupplierFormLocation(getState),
      });
      break;
    }

    case ShortlistActionTypes.REMOVED_SUPPLIER_FROM_RECOMMENDATIONS_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      track({
        event: 'Removed supplier from recommendations',
        category: 'Checklist',
        ...shortlistPropertiesGeneric(action.payload.supplier, getState()),
        location: action.payload.location,
        method: action.payload.method,
        cta: action.payload.cta,
      });
      break;
    }

    case ShortlistActionTypes.ADDED_SUPPLIER_VISIT_DATE_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { payload: editedSupplier } = action as AddedSupplierVisitDateAnalyticsAction;
      track({
        event: 'Added supplier visit date',
        ...shortlistPropertiesGeneric(editedSupplier, getState()),
        shortlistedSupplierVisitDate: editedSupplier.visitDate,
      });
      break;
    }

    case ShortlistActionTypes.EDITED_SUPPLIER_VISIT_DATE_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { payload: editedSupplier } = action as EditedSupplierVisitDateAnalyticsAction;
      track({
        event: 'Edited supplier visit date',
        ...shortlistPropertiesGeneric(editedSupplier, getState()),
        shortlistedSupplierVisitDate: editedSupplier.visitDate,
      });
      break;
    }

    case ShortlistActionTypes.REMOVED_SUPPLIER_VISIT_DATE_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const {
        payload: { supplier },
      } = action as RemovedSupplierVisitDateAnalyticsAction;
      track({
        event: 'Removed supplier visit date',
        ...shortlistPropertiesGeneric(supplier, getState()),
      });
      break;
    }

    case ShortlistActionTypes.TOGGLED_TAB_NAVIGATION_ON_SHORTLIST_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
      const { payload: tab } = action as ToggledTabNavigationOnShortlistAnalyticsAction;
      const getGenericProps = () => {
        const state = getState();
        const previousPath = getPreviousPath(state);
        const id = getCurrentUserId(state);
        return {
          id,
          previousPath,
        };
      };

      track({
        event: 'Toggled tab navigation on shortlist',
        selectedTab: tab === 'booked' ? 'yourBooked' : 'yourFavourites',
        ...getGenericProps(),
      });
      break;
    }

    default:
      break;
  }
}
